.score {
  font-size: 1rem;
  height: 2rem;
  padding: 0.5rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.5);
  &.score-first {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  &.score-last {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
  &.active {
    border: solid 3px #fff;
    border-radius: 12px;
    height: 2.75rem;
    color: #fff;
  }
}

.bg-green-50 {
  background: #22a244;
}

.bg-green-30 {
  background: #61de85;
}

.bg-yellow-30 {
  background: #fcd966;
}

.bg-orange-30 {
  background: #ffa362;
}

.bg-red-50 {
  background: #f3464e;
}
