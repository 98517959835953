.dropdown-item.btn-link:hover {
  color: #fff !important;
  text-decoration: none;
  background-color: #303333;
}

// TODO add autoprefix loader: in webpack
select {
  -webkit-appearance: none;
}

input.form-control.ignore-read-only-style {
  background-color: rgba(149, 161, 162, 0.2);
  box-shadow: none;
  cursor: pointer;
}

div.card-dropdown {
  position: absolute;
  left: 0;
  right: 0;
  top: 32px;
  z-index: 1000;
}

div.card-dropdown-overflow {
  max-height: 300%;
  overflow-y: auto;
}

@mixin cardDropdownSize($size, $pixels) {
  div.input-group-#{$size} {
    div.card-dropdown {
      top: #{$pixels}px;
    }
  }
}

@include cardDropdownSize("lg", 40);
@include cardDropdownSize("xl", 48);
@include cardDropdownSize("sm", 20);
