:global {
  body {
    background-color: $bg-lvl2;
    height: 100%;
  }

  html,
  body {
    width: 100%;
    height: 100%;
  }

  #root {
    height: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  header,
  footer {
    flex-shrink: 0;
  }

  main {
    flex-grow: 1;
    padding-top: 90px;
  }

  div[role="main"] {
    margin-top: 73px;
  }

  .required .text-secondary:after {
    content: " *";
    color: red;
  }

  sgwt-mini-footer {
    position: initial !important; // fix sgwt-mini-footer style

    footer.border {
      border-left: none !important; // fix sgwt-mini-footer borders
      border-right: none !important;
    }
  }
  .pagination {
    display: flex;
    list-style: none;
    margin-top: 20px;
    padding: 0;
  }

  .pagination a {
    cursor: default;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #8a9496;
    color: #8a9496;
    margin-left: 10px;
  }

  .pagination li:not(.disabled) a:hover {
    background-color: bisque;
    cursor: pointer;
  }

  .pagination li a {
    font-weight: bold;
  }

  .pagination li.active a {
    color: #fff;
    background: #8a9496;
  }

  .pagination li.disabled a {
    pointer-events: none;
    color: rgb(198, 197, 202);
    border: 1px solid rgb(198, 197, 202);
  }
}
