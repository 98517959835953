@import "~react-toastify/scss/main";
@import "~@sg-bootstrap/icons/scss/index";
@import "~@sg-bootstrap/core/scss/sg-bootstrap-standard";
@import "layout";
@import "dropdown";
@import "rating";

:global {
  // TODO add autoprefix loader: in webpack
  select {
    -webkit-appearance: none;
  }

  .capitalize {
    text-transform: capitalize;
  }
}
